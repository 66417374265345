import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

class MediaPage extends React.Component {
  render() {
    const { data } = this.props
    const media = data.allMediaJson.edges
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout title={siteTitle}>
        <SEO title="Media" />
  
        <article className="post-content page-template no-image">
          <header className="post-content-header">
            <h1 className="post-content-title">In the media</h1>
            <p className="post-content-excerpt">I've made appearances in many global publications over the years. Here's a select few:</p>
          </header>

          <div className="post-content-body">
            <div className="media-container">
              {media.map(({ node }) => {
                return(
                  <a className="media-item" href={node.link} rel="noopener noreferrer" target="_blank">
                      <Img style={{ maxHeight: "100%" }} imgStyle={{ objectFit: "contain" }} fixed={node.publication.childImageSharp.fixed} />
                  </a>
                )
              })}
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    },
    allMediaJson {
      edges {
        node {
          link
          publication {
            childImageSharp {
              fixed(height: 40) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
    <StaticQuery
      query={pageQuery}
      render={data => (
        <MediaPage data={data} {...props} />
      )}
    />
  )